import { Link, NavLink, useHistory, useParams, useNavigate } from 'react-router-dom';
import React from 'react';
import { Row, Col } from 'antd';
// import Logo from '../../img/logo.png';
import login from '../../img/login.png';
import { useState } from 'react';
import Cookies from 'js-cookie';

const Navbaar = () => {
  const enc_user_detail = Cookies.get('UserDetail');
  const history = useHistory();
  const [isActive, setIsActive] = useState(false);
  const [ishemBurger, sethemBurger] = useState(false);

  const navToggle = event => {
    setIsActive(current => !current);
  };

  const hemBurger = event => {
    sethemBurger(current => !current);
  };

  const RedirectToDashboard = () => {
    history.push(`/pickup`);
    window.location.reload();
  };
  return (
    <>
      <Row>
        <div className="container wid_1">
          <Col md={24}>
            <div className="navbaar">
              <div className="logo">
                <img style={{ height: '85px', width: '200px' }} src={'./logo.png'} />
              </div>
              <div className={isActive ? 'hideRemove nav_v' : 'nav_v'}>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/about">About Us</a>
                  </li>
                  <li>
                    <a href="/services">Services</a>
                  </li>
                  <li>
                    <a href="/Notification">Portfolio</a>
                  </li>
                  <li>
                    <a href="/pricing">Pricing</a>
                  </li>
                  <li>
                    <a href="/blog">Blog</a>
                  </li>
                  <li>
                    <a href="/contact">Contact Us</a>
                  </li>
                  <li onClick={() => history.push(`/my-account`)}>
                    <img src={login} className="login_icon" />
                  </li>
                  <div className="m_1">
                    {enc_user_detail ? (
                      <NavLink className="btn_1" to="#" onClick={RedirectToDashboard}>
                        My Account
                      </NavLink>
                    ) : (
                      <NavLink className="btn_1" to="/login">
                        Sign In
                      </NavLink>
                    )}
                  </div>
                </ul>
              </div>
              <a className="icon" onClick={navToggle}>
                <div id="nav-icon3" onClick={hemBurger} className={isActive ? 'open' : ''}>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </a>
            </div>
          </Col>
        </div>
      </Row>
    </>
  );
};

export default Navbaar;
